import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Img1 from "../assets/erental.png"
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import Img2 from "../assets/deepminds.png"
import Img3 from "../assets/Hubbiz.png"

const Work = () => {
  return (
    <div id='work' className='bg-white md:text-sm'>
      <div className='pt-28 px-4 container mx-auto'>
        <motion.div 
        variants={fadeIn('up', 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.2}}
        className='text-center space-y-5'>
        <h2 className='text-2xl font-secondary font-bold text-herobg'>Projects</h2>
        <p className='md:w-1/2 mx-auto lg:text-lg text-sm'>what I have Achieved in the last 3years of experience.</p>
        </motion.div>
        {/* Service categories */}
        <div className='py-12 mx-auto'>
        <Tabs>
          <TabList 
          className="flex flex-wrap gap-1 items-center justify-center font-medium text-sm md:gap-8 md:text-lg">
            <Tab>Project 1</Tab>
            <Tab>Project 2</Tab>
            <Tab>Project 3</Tab>
          </TabList>

          <TabPanel>
          <motion.div 
            variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount:0.2}}
            className='flex flex-col md:flex-row gap-8 mt-8'>
              <div className='md:w-1/2 bg-white rounded-sm p-12 font-secondary'>
                <h3 className='text-3xl font-normal text-primary mb-4'>Car rental Services</h3>
                <p className='mb-8 leading-6 text-justify'>This project is to redefine the car rental experience by offering a diverse fleet of vehicles that cater to various preferences and requirements.</p>
                <h4 className='text-xl mb-4 font-medium text-black'>Technologies Used</h4>
                <ul className='list-disc list-inside space-y-3'>
                <li>HTML/CSS/JS</li>
                  <li>ReactJs</li>
                  <li>Bootsrap</li>
                  <li>REST API</li>
                </ul>
              </div>
              <div className='w-full md:w-1/2'>
                <a href="https://erental.bizsupport.com.ng/">
                <img src={Img1} alt="" className='w-full h-auto rounded-sm' />
                </a>
              </div>
            </motion.div>
          </TabPanel>

          <TabPanel>
            <motion.div 
            variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount:0.2}}
            className='flex flex-col md:flex-row gap-8 mt-8'>
              <div className='md:w-1/2 bg-white rounded-sm p-12 font-secondary'>
                <h3 className='text-3xl font-normal text-primary mb-4'>Deepminds</h3>
                <p className='mb-8 leading-6 text-justify'>Deepminds is your all-in-one mental wellness companion, offering tools to manage stress, improve focus, and build emotional resilience. From guided meditations and mood tracking to personalized self-care routines and daily affirmations, Deepminds empowers you to prioritize your peace of mind and develop healthy habits for a happier, more balanced life.</p>
                <h4 className='text-xl mb-4 font-medium text-black'>Technologies Used</h4>
                <ul className='list-disc list-inside space-y-3'>
                <li>HTML/CSS/JS</li>
                  <li>ReactJs</li>
                  <li>TailwindCss</li>
                  <li>REST API</li>
                </ul>
              </div>
              <div className='w-full md:w-1/2'>
                <a href="http://deepminds.bizsupport.com.ng/">
                <img src={Img2} alt="" className='w-full h-auto rounded-sm' />
                </a>
              </div>
            </motion.div>
          </TabPanel>

          <TabPanel>
          <motion.div 
            variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount:0.2}}
            className='flex flex-col md:flex-row gap-8 mt-8'>
              <div className='md:w-1/2 bg-white rounded-sm p-12 font-secondary'>
                <h3 className='text-3xl font-normal text-primary mb-4'>Hubbiz</h3>
                <p className='mb-8 leading-6 text-justify'>Hubbiz goal is to empower businesses of all sizes by providing a wide range of services designed to support your growth and success.</p>
                <h4 className='text-xl mb-4 font-medium text-black'>Technologies Used</h4>
                <ul className='list-disc list-inside space-y-3'>
                <li>HTML/CSS/JS</li>
                  <li>ReactJs</li>
                  <li>TailwindCss</li>
                  <li>REST API</li>
                </ul>
              </div>
              <div className='w-full md:w-1/2'>
                <a href='https://hubbiz.bizsupport.com.ng/'>
                <img src={Img3} alt="" className='w-full h-auto rounded-sm' />
                </a>
              </div>
            </motion.div>
          </TabPanel>

        </Tabs>
        </div>
      </div>
    </div>
  )
};

export default Work;